.card {
    margin: 20px;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
}

.card-not-golden {
    background-color: rgba(255, 0, 0, 0.64);
}

.card-golden {
    background-color: rgba(0, 255, 0, 0.64);
}

.cardText {
    font-size: 2em;
    font: black;
}

.link-text {
    color: black;
}


.footer {
    width: 100%;
    text-align: center;
}

.full-height {
    height: 100vh;
    background-color: #f8f9fa; /* Light background color */
}